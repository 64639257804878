jQuery(function( $ ){

	if( $( document ).scrollTop() > 0 ){
		$( '.site-header' ).addClass( 'dark' );			
	}

	// Add opacity class to site header
	$( document ).on('scroll', function(){

		if ( $( document ).scrollTop() > 0 ){
			$( '.site-header' ).addClass( 'dark' );			

		} else {
			$( '.site-header' ).removeClass( 'dark' );			
		}

	});


	// Add opacity class to site header
	$( document ).on('scroll', function(){

		if ( $( document ).scrollTop() > 0 ){
			$( '#iwpmenu_icon' ).addClass( 'dark' );			

		} else {
			$( '#iwpmenu_icon' ).removeClass( 'dark' );			
		}

	});


	$( '.nav-primary .genesis-nav-menu, .nav-secondary .genesis-nav-menu' ).addClass( 'responsive-menu' ).before('<div class="responsive-menu-icon"></div>');

	$( '.responsive-menu-icon' ).click(function(){
		$(this).next( '.nav-primary .genesis-nav-menu,  .nav-secondary .genesis-nav-menu' ).slideToggle();
	});

	$( window ).resize(function(){
		if ( window.innerWidth > 800 ) {
			$( '.nav-primary .genesis-nav-menu,  .nav-secondary .genesis-nav-menu, nav .sub-menu' ).removeAttr( 'style' );
			$( '.responsive-menu > .menu-item' ).removeClass( 'menu-open' );
		}
	});

	$( '.responsive-menu > .menu-item' ).click(function(event){
		if ( event.target !== this )
		return;
			$(this).find( '.sub-menu:first' ).slideToggle(function() {
			$(this).parent().toggleClass( 'menu-open' );
		});
	});

	// Toggle sub-menu on click... VSH

	$(".sub-menu").hide();

	if ($(window).width() > 1199) {

	    $('.menu-item-has-children').hover(function(){
		    $(this).addClass("open");
	     	$(this).find(".sub-menu").slideDown('fast').fadeIn('750').addClass('openSub');
		});

		$('.menu-item-has-children').mouseleave(function(){
		    $(this).delay(10000).removeClass("open");
    		$(this).find(".sub-menu").delay(10000).slideUp('slow').fadeOut('750').removeClass('openSub');
		});

	}
	else {

	   	$(".menu-item-has-children > a").click(function (e) {
	        e.preventDefault();
	        $(this).parents(".menu-item-has-children").toggleClass("open");
	        $(this).parents(".menu-item-has-children").find(".sub-menu").fadeToggle('750');
	    });
	    
	}


});